<template lang = "pug">
div
  fi-form-field.new(:label = '$t("reportingType")' :label-cols = 3 )
        fi-select(
          v-model = 'addData.assetReportingType'
          :options       = 'reportingTypes'
          sm
        )
  .loan-collateral-asset-guarantees-fields-edit.animated.fadeIn.flex-row-direction.justify-content-space-evenly
    div.width-50.margin-right-20
      fi-form-field(:label = '$t("registerNumber")')
        input.form-control.form-control-sm(
          v-model.number = 'addData.registerNumber'
          type           = 'text'
        )
      fi-form-field(:label = '$t("type")')
        fi-select(
          v-model.number = 'addData.warrantyTypeId'
          :options       = 'optionsFromClassifier("warrantyTypes")'
          sm
        )
      fi-form-field.animated.fadeIn(
        v-if   = 'isShowName'
        :label = '$t("immovableName")'
      )
        input.form-control.form-control-sm(
          v-model.number = 'addData.immovableName'
          type           = 'text'
        )
      fi-form-field(:label = '$t("warrantyCadastralUnitIdentifier")')
        input.form-control.form-control-sm(
          v-model = 'addData.warrantyCadastralUnitIdentifier'
          type    = 'text'
        )
      fi-form-field(:label = '$t("country")')
        fi-select(
          v-model.number = 'addData.warrantyCountryId'
          :options       = 'optionsFromClassifier("countries")'
          sm
        )
      fi-form-field(:label = '$t("county")')
        fi-select(
          v-model.number = 'addData.warrantyCountyId'
          :options       = 'counties'
          sm
        )
      fi-form-field(:label = '$t("city")')
        input.form-control.form-control-sm(
          v-model.number = 'addData.warrantyCity'
          type           = 'text'
        )
      fi-form-field(:label = '$t("address")')
        input.form-control.form-control-sm(
          v-model.number = 'addData.warrantyAddress'
          type           = 'text'
        )
      fi-form-field(:label = '$t("postalСode")')
        input.form-control.form-control-sm(
          v-model.number = 'addData.warrantyPostalCode'
          type           = 'text'
        )
      fi-form-field(:label = '$t("warrantyOwnerName")')
        .flex-row-direction
          fi-customer-select(
            v-model      = 'selectedCustomerModel'
            :placeholder = '$t("existingCustomerPlaceholder")'
          )
          .input-group-append
            button.btn.btn-sm.btn-primary(
              @click.prevent = 'addSelectedUserToOwnerList'
            )
              i.far.fa-plus-square.fa-lg
      div
        span.pr-2.bold.offset-sm-6 - {{ $t('or') }} -
        customer-new-brief(
          v-if    = 'createNewCustomer'
          @cancel = 'createNewCustomer = false'
          @submit = 'onCreateCustomer'
        )
        button.btn.btn-link.px-0(
          v-else
          @click.prevent = 'createNewCustomer = true'
        ) {{ $t('createCustomer') }}
        .flex-column-direction(v-if = 'showOwnerList')
          <table style="width:100%">
            <tr style = 'text-align: center;'>
              <th style = 'width: 50%'></th>
              <th style = 'width: 10%'>{{ $t("primary") }}</th>
              <th style = 'width: 20%'>{{ $t("ownerShip") }}</th>
              <th style = 'width: 10%'></th>
            </tr>
            template(v-for = '(owner, index) in assetOwner')
              <tr>
                <td class = 'bold'> {{ owner.customerName }} </td>
                <td style = 'display: flex;'>
                  input(
                    style   = 'width: 100%; height: 35px;'
                    v-model = 'owner.primaryOwner'
                    type    = 'checkbox'
                    @change = 'selectOwnerPrimary(index)'
                  )
                </td>
                <td>
                  percent-input(
                    v-model = 'owner.ownershipPercentage')
                </td>
                <td style = 'text-align: center;'>
                  i.far.fa-trash-alt.fa-lg.text-danger.cursor-pointer(
                    @click = 'removeOwnerFromOwnerList(index)'
                  )
                </td>
              </tr>
          </table>
      fi-form-field(:label = '$t("assetInfo")')
        fi-rich-text-editor(v-model = 'assetData.assetInfo')
      fi-form-field(:label = '$t("internalComment")')
        fi-rich-text-editor(v-model = 'assetData.comment')
    div.width-50
      fi-form-field(:label = '$t("valueType")')
        fi-select(
          v-model.number = 'addData.valueType'
          :options       = 'valueTypes'
          sm
        )
      fi-form-field(:label = '$t("valuationType")')
        fi-select(
          v-model.number = 'addData.valuationType'
          :options       = 'valuationTypes'
          sm
        )
      fi-form-field(:label = '$t("locationCode")')
        fi-select(
          v-model.number = 'addData.collateralLocationCode'
          :options       = 'locationCodes'
          sm
        )
      fi-form-field(:label = '$t("assetStatus")')
        fi-select(
          v-model.number = 'addData.assetStatus'
          :options       = 'assetStatus'
          sm
        )
      //- fi-form-field(:label = '$t("appraisalPartner")')
      //-   fi-customer-select(v-model = 'selectedAppraisalPartnerModel')
      fi-form-field(:label = '$t("assetSoldValue")')
        input.form-control.form-control-sm(
          v-model.number = 'addData.assetSoldValue'
          type           = 'text'
        )
      fi-form-field(:label = '$t("soldDate")')
        fi-datepicker(
          v-model = 'addData.soldDate'
          sm
        )
      fi-form-field(:label = '$t("warrantyArea")')
        .form-row
          .col
            input.form-control.form-control-sm(
              v-model.number = 'addData.warrantyArea'
              type           = 'text'
            )
          .col
            fi-select(
              v-model.number = 'addData.areaSizeTypeId'
              :options       = 'optionsFromClassifier("immovableAreaSizeType")'
              required
              sm
            )
      fi-form-field(
        :label = '$t("value")'
        :state = '!state.assetData.value.$error'
      )
        template(#error)
          .error-message(v-if = '!state.assetData.value.required') {{ $t('common:required') }}
        fi-money-input.form-control.form-control-sm(
          v-model.number = 'assetData.value'
          :class         = '{ "is-invalid": state.assetData.value.$error }'
        )
      fi-form-field(
        :label = '$t("valueDate")'
        :state = '!state.assetData.valueDate.$error'
      )
        template(#error)
          .error-message(v-if = '!state.assetData.valueDate.required') {{ $t('common:required') }}
        fi-datepicker(
          v-model = 'assetData.valueDate'
          :class         = '{ "is-invalid": state.assetData.valueDate.$error }'
          sm
        )
      fi-form-field(
        :label = '$t("allocatedValue")'
      )
        fi-money-input.form-control.form-control-sm(
          v-model.number = 'assetData.allocatedValue'
        )
      fi-form-field(:label = '$t("appraisalReport")')
        fi-switch.mt-1(
          v-model   = 'assetData.appraisalReport'
          :on-text  = '$t("common:yes")'
          :off-text = '$t("common:no")'
        )
      .animated.fadeIn(v-if = 'assetData.appraisalReport')
        fi-form-field(:label = '$t("appraisalPartnerName")')
          input.form-control.form-control-sm(
            v-model.number = 'assetData.appraisalPartnerName'
            type           = 'text'
          )
        fi-form-field(:label = '$t("appraisalValue")')
          fi-money-input.form-control.form-control-sm(
            v-model.number = 'assetData.appraisalValue'
            type           = 'text'
          )
      fi-form-field(:label = '$t("insuranceMandatory")')
        fi-switch.mt-1(
          v-model   = 'assetData.insuranceMandatory'
          :on-text  = '$t("common:yes")'
          :off-text = '$t("common:no")'
        )
      fi-form-field(:label = '$t("insuranceCompany")')
        input.form-control.form-control-sm(
          v-model.number = 'assetData.insuranceCompany'
          type           = 'text'
        )
      fi-form-field(:label = '$t("insuredUntil")')
        fi-datepicker(
          v-model = 'assetData.insuredUntil'
          sm
        )

</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import PercentInput from '@/components/PercentInput'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'loan-collateral-asset-guarantees-fields-edit',

  components: {
    FiRichTextEditor,
    FiMoneyInput,
    FiFormField,
    FiSelect,
    FiDatepicker,
    FiSwitch,
    FiCustomerSelect,
    CustomerNewBrief,
    PercentInput
  },

  props: {
    addData: {
      type: Object,
      required: true
    },
    assetData: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    appraisalPartnerData: {
      type: Object,
      default: null
    },
    assetOwner: {
      type: Array,
      default: () => []
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    createNewCustomer: false,
    selectedCustomer: null,
    selectedAppraisalPartner: vm.appraisalPartnerData,
    showOwnerList: false,
    deleting: false,
    ownerList: [],
    assetOwnerData: []
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromCountiesByCountry', 'classifierById']),
    counties () {
      return this.optionsFromCountiesByCountry(this.addData.warrantyCountryId)
    },
    assetStatus () {
      return this.optionsFromClassifier('ASSET_STATUS', true)
    },
    reportingTypes () {
      return this.optionsFromClassifier('TYP_PRTCTN', true)
    },
    valueTypes () {
      return this.optionsFromClassifier('TYP_PRTCTN_VL', true)
    },
    valuationTypes () {
      return this.optionsFromClassifier('PRTCTN_VLTN_APPRCH', true)
    },
    locationCodes () {
      return this.optionsFromClassifier('ISO3166_NUTS_DSJNT', true)
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
      }
    },
    selectedAppraisalPartnerModel: {
      get () {
        return this.selectedAppraisalPartner
      },
      set (selected) {
        this.selectedAppraisalPartner = selected
        this.addData.appraisalPartnerId = selected.id
      }
    },
    isShowName () {
      return /land|lot/i.test(this.classifierById('warrantyTypes', this.addData.warrantyTypeId).code)
    },
    lengthOwnerList () {
      return this.assetOwner.length
    }
  },

  watch: {
    lengthOwnerList: {
      handler (value) {
        value ? this.showOwnerList = true : this.showOwnerList = false
      },
      deep: true
    }
  },

  async created () {
    await this.loadEditableClassifiers()
    if (this.addData.assetsOwners.length) {
      this.showOwnerList = true
      this.ownerList = this.addData.assetsOwners
    }
    if (!this.assetData.value) {
      if (this.appraisalPartnerData) {
        this.selectedAppraisalPartner = this.appraisalPartnerData
      }
    }
  },

  methods: {
    ...mapActions('classifiers', ['loadEditableClassifiers']),
    async onCreateCustomer ({ customer }) {
      this.createNewCustomer = false
      this.selectedCustomerModel = customer
    },
    addSelectedUserToOwnerList () {
      if (this.selectedCustomer) {
        this.assetOwner.push({ customerName: this.selectedCustomer.customerName, primaryOwner: false, ownershipPercentage: 0, customerId: this.selectedCustomer.id })
      }
    },
    selectOwnerPrimary (index) {
      if (this.assetOwner[index].primary) {
        this.assetOwner.forEach((el, id) => {
          if (index !== id) {
            el.primary = false
          }
        })
      }
    },
    removeOwnerFromOwnerList (index) {
      this.assetOwner.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row-direction {
  display: flex;
}
.flex-column-direction {
  display: flex; flex-direction: column;
}
.bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.width-50 {
  width: 50%;
}
.margin-right-20 {
  margin-right: 20px
}
</style>

<i18n>
en:
  existingCustomerPlaceholder:     "Select customer"
  registerNumber:                  "Register number"
  type:                            "Immovable type"
  immovableName:                   "Immovable name"
  country:                         "Country"
  county:                          "County"
  createCustomer:                  "Create customer"
  city:                            "City"
  address:                         "Address"
  value:                           "Value"
  valueDate:                       "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                 "Appraisal report"
  appraisalPartnerName:            "Appraisal partner"
  appraisalValue:                  "Appraisal value"
  insuranceMandatory:              "Insurance mandatory"
  insuranceCompany:                "Insurance company"
  insuredUntil:                    "Insured until"
  assetInfo:                       "Asset info"
  internalComment:                 "Internal comments"
  warrantyCadastralUnitIdentifier: "Cadastral unit identifier"
  warrantyArea:                    "Area"
  warrantyOwnerName:               "Owner name"
  primary:                         "Primary"
  ownerShip:                       "Ownership"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
et:
  existingCustomerPlaceholder:     "Select customer"
  registerNumber:                  "Register number"
  type:                            "Immovable type"
  immovableName:                   "Immovable name"
  country:                         "Country"
  county:                          "County"
  createCustomer:                  "Create customer"
  city:                            "City"
  address:                         "Address"
  value:                           "Value"
  valueDate:                       "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                 "Appraisal report"
  appraisalPartnerName:            "Appraisal partner"
  appraisalValue:                  "Appraisal value"
  insuranceMandatory:              "Insurance mandatory"
  insuranceCompany:                "Insurance company"
  insuredUntil:                    "Insured until"
  assetInfo:                       "Asset info"
  internalComment:                 "Internal comments"
  warrantyCadastralUnitIdentifier: "Cadastral unit identifier"
  warrantyArea:                    "Area"
  warrantyOwnerName:               "Owner name"
  primary:                         "Primary"
  ownerShip:                       "Ownership"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
ru:
  existingCustomerPlaceholder:     "Select customer"
  registerNumber:                  "Register number"
  type:                            "Immovable type"
  immovableName:                   "Immovable name"
  country:                         "Country"
  county:                          "County"
  createCustomer:                  "Create customer"
  city:                            "City"
  address:                         "Address"
  value:                           "Value"
  valueDate:                       "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                 "Appraisal report"
  appraisalPartnerName:            "Appraisal partner"
  appraisalValue:                  "Appraisal value"
  insuranceMandatory:              "Insurance mandatory"
  insuranceCompany:                "Insurance company"
  insuredUntil:                    "Insured until"
  assetInfo:                       "Asset info"
  internalComment:                 "Internal comments"
  warrantyCadastralUnitIdentifier: "Cadastral unit identifier"
  warrantyArea:                    "Area"
  warrantyOwnerName:               "Owner name"
  primary:                         "Primary"
  ownerShip:                       "Ownership"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
</i18n>
